<template>
  <div class="moreNews">
    <header>
      <div class="g-header" style="background: #fff">
        <div class="left" @click="changego">
          <van-icon name="arrow-left" color="#404040" size="20px" />
        </div>
        <div class="middle fs-18 c_404040">{{ $t('touzikuaixun') }}</div>
        <div class="right">
          <div class="btn-save fs-16"></div>
        </div>
      </div>
      <div class="blockHeight"></div>
    </header>
    <div class="mescroll scroller" style="overflow-y:scroll">
      <div>
        <div class="component-news" 
        > 
          <div class="hot-topic">
            <!-- <h2 class="hot-title fw fs-20">{{ $t('remenhuati') }}</h2> -->
            <div class="hot-content">
              <div class="hot-item" v-for="(item, index) in tops" :key="index" @click="changedetail(item)">
                <p class="title">{{ item.title }}</p>
                <p class="join-count fs-14 mr-t5">
                  <span>{{ item.edit_day }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- <div class="media">
            <van-tabs v-model="active" swipeable sticky>
              <van-tab v-for="(item, index) in articleTabs" :key="index">
                <template #title> {{ $t('news'+item.id) }} </template>
                <template #default>
                  <div
                    class="news boderBottom"
                    v-if="item.articles.length != 0"
                  >
                    <div class="news-info">
                      <h3>{{ item.articles }}</h3>
                      <div class="time fs-10 c_a0a0a0">
                        <span class="mr-l20">{{ item.articles }}</span>
                      </div>
                    </div>
                    <img
                      src="@/assets/image/new1.png"
                      class="news-img"
                      alt=""
                    />
                  </div>
                  <div v-else>{{$t('wuTps')}}</div>
                </template>
              </van-tab>
            </van-tabs>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import homeApi from "@/api/home";
export default {
  name: "MoreNews",
  components: {},
  data() {
    return {
      active: 0,
      tops: null,
      articleTabs: null,
      Height: "",
    };
  },
  methods: {
    changego() {
      this.$router.go(-1);
    },
    info() {
      this.$toast.loading({
        message: this.$t('jiazaizhong'),
        forbidClick: true,
        duration: 0,
      });
      homeApi
        .articleList()
        .then((data) => {
          console.log(data.data);
          this.tops = data.data.tops;
          this.tops.forEach((v, i) => {
            if (i == 0) {
              v.readers = '2024-4-10';
            } else if (i == 1) {
              v.readers = '2024-1-10';
            } else if (i == 2) {
              v.readers = '2023-12-1';
            } else if (i == 3) {
              v.readers = '2023-11-12';
            } else if (i == 4) {
              v.readers = '2023-10-10';
            } else if (i == 5) {
              v.readers = '2023-10-9';
            } else if (i == 6) {
              v.readers = '2023-8-9';
            } else if (i == 7) {
              v.readers = '2023-5-6';
            } else if (i == 8) {
              v.readers = '2023-1-10';
            } else if (i == 9) {
              v.readers = '2022-12-1';
            } else if (i == 10) {
              v.readers = '2022-11-5';
            } else if (i == 11) {
              v.readers = '2022-10-15';
            } else if (i == 12) {
              v.readers = '2022-10-1';
            }
            

          });
          this.articleTabs = data.data.articleTabs;
          this.$toast.clear();
        })
        .catch((err) => {
          this.$toast.clear();
          this.$toast.fail(err.msg);
        });
    },
    changedetail(item) {
      console.log(item);
      let news = JSON.stringify(item);
      this.$router.push({
        path: "/AticleDetail",
        query: {
          news: news,
        },
      });
    },
  },
  mounted() { 
    this.Height = document.documentElement.clientHeight;
    window.onresize = () => {
      this.Height = document.documentElement.clientHeight;
    };
  },
  created() {
    this.info();
  },
};
</script>
<style lang="less" scoped>
.moreNews {
  height: 100%;
  background-color: #fff;
}

.mescroll {
  flex: 1;
}

.component-news {
  background-color: #edebf1;
  height: 100%;
  // width: 95%;
}

.hot-topic {
  padding: 0.2rem;
}

.hot-content {
  margin-top: 0.2rem;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hot-item {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  // -webkit-box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  // box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
  padding: 0.2rem;
  padding-top: 0.5rem;
  display: flex;
  margin: 0.1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: left;

  .title {
    min-height: 1.4rem;
    // color: #208dff;
    // color: #404040;
    // color:#dee4e9;
    color: #404040;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    font-size: 0.5rem;
  }
}

.join-count {
  color: #404040;
  opacity: 1.8;
  text-align: right;
}

.mr-t5 {
  margin-top: 0.1rem;
}

.hot-title {
  text-align: left;
  color: #fff;
}

.news {
  padding: 0.3rem;
  display: flex;
}

.news-info {
  margin-right: 0.4rem;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  text-align: left;
}

h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0.3rem;
  flex: 1;
}

.mr-l20 {
  margin-right: 0.4rem;
}

.news-img {
  width: 2.4rem;
  height: 2.2rem;
}
.scroller::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scroller::-webkit-scrollbar-track {
  background-color:transparent;
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}
.scroller::-webkit-scrollbar-thumb {
  background-color: rgb(147,147,153,0.5);
  -webkit-border-radius: 2em;
  -moz-border-radius: 2em;
  border-radius:2em;
}

</style>